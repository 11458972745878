import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export { PrivateRoute };

function PrivateRoute({ children }) {
    const location = useLocation();
    const { token } = useSelector((state) => state.auth);

    //Setting redirect URL after user logged in (If user not logged in yet)
    if (!token) {
        localStorage.setItem('loginRedirectURL', window.location.href);
    }
    return token ? (
        children
    ) : (
        <Navigate to="/login" replace state={{ from: location }} />
    );
}
