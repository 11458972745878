import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input } from 'antd';
import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ErrorMsg from '../../components/ErrorMsg/ErrorMsg';
import SuccessMsg from '../../components/SuccessMsg/SuccessMsg';
import { useUserService } from '../../services';
import { MailOutlined } from '@ant-design/icons';
import './ForgotPassword.css';

const ForgotPassword = (props) => {
    const navigate = useNavigate();

    const { token } = useSelector((state) => state.auth);

    const initialInputUserState = {
        email: '',
        password: ''
    };

    const [inputUser, setInputUser] = useState(initialInputUserState);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [linkSent, setLinkSent] = useState(false);

    //Helper for loaders
    const [loading, setLoading] = useState(false);

    const userService = useUserService();

    useEffect(() => {
        //redirect to home if already logged in
        if (token) {
            navigate('/');
        }
        if (props.success) {
            console.log(props.success);
            setSuccessMsg(props.success);
        }
    }, [token, props.success, navigate]);

    const handleInputChange = (field, value) => {
        setInputUser({
            ...inputUser,
            [field]: value
        });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let user = {
                email: inputUser.email
            };
            await userService.forgotPassword(user);
            setLinkSent(true);
            setErrorMsg(null);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLinkSent(false);
            setLoading(false);
            setErrorMsg(
                err?.response?.data?.msg
            );
        }
    };

    return (
        <Spin spinning={loading}>
            <div className="forgotPasswordForm">
                <Row className="sample">
                    <Col span={8}></Col>

                    <Col span={8}>
                        <h2 className="productTitle">
                            <strong>StoreSync</strong>
                        </h2>
                        <Card className="forgotPasswordCard">
                            <h2>
                                <strong>Forgot password</strong>
                            </h2>
                            <Form
                                name="normal_login"
                                className="forgot-password-form"
                                layout="vertical"
                                initialValues={{
                                    remember: true
                                }}
                                onFinish={handleSubmit}
                            >
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message:
                                                'The input is not valid E-mail!'
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!'
                                        }
                                    ]}
                                >
                                    <Input
                                        prefix={
                                            <MailOutlined className="site-form-item-icon" />
                                        }
                                        placeholder="Enter your email"
                                        onChange={(e) =>
                                            handleInputChange(
                                                'email',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Row className="errorMsg">
                                    {errorMsg && <ErrorMsg msg={errorMsg} />}
                                </Row>
                                <Row className="successMsg">
                                    {successMsg && (
                                        <SuccessMsg msg={successMsg} />
                                    )}
                                </Row>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="resetPasswordButton"
                                    >
                                        Reset Password
                                    </Button>
                                    {linkSent &&
                                        <div className="linkSentMessage">
                                            We sent a link to your email to reset your password
                                        </div>
                                    }
                                </Form.Item>
                                <a
                                    className="goBack"
                                    href="/login"
                                >
                                    Back
                                </a>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            </div>
        </Spin>
    );
};

export default ForgotPassword;
