//Purpose: Creator for actions related to stores. Imported StoreService to make async HTTP requests with trigger dispatch on the result

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BASE_API_URL } from '../constants/routes';
import useFetchWrapper from '../helpers/FetchWrapper';
import { StoreActions } from '../redux/slices/store.slice';
import { Notification } from '../utilities/notification';
import axios from 'axios';

function useStoreService() {
    const baseUrl = `${BASE_API_URL}/store`;
    const fetchWrapper = useFetchWrapper();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return {
        getStores,
        deleteStore,
        getAllCount,
        getOne,
        addStore,
        update,
        remove,
        getProductsCount,
        sync,
        getOneProduct,
        getAllSourceStores,
        getAllDestinationStores,
        getDestinationStores,
        getSourceStores,
        addStoreMap,
        deleteSourceStore,
        deleteDestinationStore,
        getProducts,
        getAllStores,
        syncProducts,
        getSyncLogs
    };

    async function getStores(queries) {
        try {
            console.log('runn');
            const overAllRoute = `${baseUrl}/get_all_stores`;
            const data = {
                page: queries?.page || 1,
                pageSize: queries?.pageSize || 10,
                searchText: queries?.searchText || '',
                sort: "asc",
            };
            const stores = await fetchWrapper.post(overAllRoute, data);
            if (stores.data.data) {
                dispatch(StoreActions.setStores(stores.data.data));
                return stores?.data;
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function getAllStores() {
        try {
            const stores = await fetchWrapper.get(`${baseUrl}/stores_list`);
            if (stores.data.data) {
                return stores?.data?.data;
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function getDestinationStores(queries) {
        try {
            const data = {
                page: queries?.page || 1,
                pageSize: 10,
                searchText: queries?.searchText || '',
                sort: "asc",
                sourceStoreSyncId: queries?.storeSyncId
            };
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/store_map/get_destination_stores`, data
            );
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function getSourceStores(queries) {
        try {
            const data = {
                page: queries?.page || 1,
                pageSize: 10,
                searchText: queries?.searchText || '',
                sort: "asc",
                destinationStoreSyncId: queries?.storeSyncId
            };
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/store_map/get_source_stores`, data
            );
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function addStoreMap(request) {
        try {
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/store_map/add_store_map`, request
            );
            return response;
        } catch (err) {
            console.log(err);
            return err?.response?.data;
        }
    }

    async function syncProducts(request) {
        try {
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/sync/products`, request
            );
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async function getSyncLogs(request) {
        try {
            const data = {
                ...request,
                sort: "desc",
                pageSize: 10,
            };
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/sync_log/all_syncs`, data
            );
            
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function deleteStore(storeId) {
        try {
            const data = {storeSyncId : storeId};
            const overallRoute = `${baseUrl}/delete`;
            await fetchWrapper.post(overallRoute, data);
            navigate('/home');
        } catch (err) {
            console.log(err);
        }
    }

    async function deleteSourceStore(request) {
        try {
            const overallRoute = `${BASE_API_URL}/store_map/delete_source`;
            const response = await fetchWrapper.post(overallRoute, request);
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function deleteDestinationStore(request) {
        try {
            const overallRoute = `${BASE_API_URL}/store_map/delete_destination`;
            const response = await fetchWrapper.post(overallRoute, request);
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function getProducts(queries) {
        try {
            const data = {
                page: queries?.page || 1,
                pageSize: 10,
                searchText: queries?.searchText || '',
                sort: "asc",
                sourceStoreSyncId: queries?.sourceStoreSyncId,
                destinationStoreSyncId: queries?.destinationStoreSyncId,
            };
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/product/get_products`, data
            );
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async function getAllSourceStores() {
        try {
            const response = await fetchWrapper.post(
                `${baseUrl}/store_map/get_source_stores`
            );
            return response;
        } catch (err) {
            console.log(err);
        }
    }
    async function getAllDestinationStores() {
        try {
            const response = await fetchWrapper.post(
                `${baseUrl}/store_map/get_destination_stores`
            );
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function getAllCount() {
        try {
            const numStores = await fetchWrapper.get(`${baseUrl}/count`);
            return numStores;
        } catch (err) {
            console.log(err);
        }
    }

    async function getOne(storeId) {
        try {
            const overallRoute = `${baseUrl}/${storeId}`;
            const store = await fetchWrapper.get(overallRoute);
            return store;
        } catch (err) {
            console.log(err);
        }
    }

    async function addStore(store) {
        try {
            const overAllRoute = `${baseUrl}/create_store`;
            const storeData = {
                storeUrl: store.store_url,
                type: store.type
            };
            const res = await fetchWrapper.post(overAllRoute, storeData);
            return res;
        } catch (err) {
            console.log(err);
            return err?.response?.data?.data;
        }
    }

    async function update(storeId, store) {
        try {
            const overallRoute = `${baseUrl}/${storeId}`;

            await fetchWrapper.put(overallRoute, store);
        } catch (err) {
            console.log(err);
        }
    }

    async function remove(storeId) {
        try {
            const overallRoute = `${baseUrl}/${storeId}`;
            await fetchWrapper.delete(overallRoute);
            navigate('/stores');
        } catch (err) {
            console.log(err);
        }
    }

    async function getProductsCount(storeId) {
        try {
            const overallRoute = `${baseUrl}/${storeId}/products/count`;
            const numProducts = await fetchWrapper.get(overallRoute);
            return numProducts;
        } catch (err) {
            console.log(err);
        }
    }

    async function sync(storeId) {
        try {
            const overallRoute = `${baseUrl}/${storeId}/products`;
            await fetchWrapper.post(overallRoute);
            navigate(`/stores/${storeId}/products`);
        } catch (err) {
            console.log(err);
        }
    }

    async function getOneProduct(storeId, productId) {
        try {
            const overallRoute = `${baseUrl}/${storeId}/products/${productId}`;
            const product = fetchWrapper.get(overallRoute);
            return product;
        } catch (err) {
            console.log(err);
        }
    }
}

export { useStoreService };
