import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useOAuthService } from '../../services';

import './OAuthEnd.css';

//Once OAuth is done, user will be redirected to this page
const OAuthEnd = () => {
    const [searchParams] = useSearchParams();

    const { endOAuth } = useOAuthService();

    useEffect(() => {
        sendQueryParams();
    }, []); //eslint-disable-line

    // Send Request to backend
    const sendQueryParams = async () => {
        const { code, hmac, host, shop, state, timestamp } = getQueryParams();

        //Checking query params to process the request
        if (hmac && host && shop) {
            await endOAuth({ code, hmac, host, shop, state, timestamp });
        }
    };

    //Getting Query Params
    const getQueryParams = () => {
        const code = searchParams.get('code');
        const hmac = searchParams.get('hmac');
        const host = searchParams.get('host');
        const shop = searchParams.get('shop');
        const state = searchParams.get('state');
        const timestamp = searchParams.get('timestamp');

        return { code, hmac, host, shop, state, timestamp };
    };
    return <div></div>;
};

export default OAuthEnd;
