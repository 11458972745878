import axios from 'axios';
import { BASE_API_URL } from '../../constants/routes';
import { AuthActions } from '../slices/auth.slice';
import { UserActions } from '../slices/user.slice';

//Action creator for checking user's autorization
export const checkAuthorization = (token, refreshToken) => {
    return async (dispatch) => {
        try {
            dispatch(AuthActions.setAuth({ token, refreshToken }));
            const userData = await axios.get(`${BASE_API_URL}/users/info`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (userData.data.data) {
                dispatch(UserActions.setUser(userData.data.data));
            }
        } catch (err) {
            console.log('Error - Check Authorization Action Error - ', err);
            throw err;
        }
    };
};
