import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    store: null,
    stores: []
};

const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        //Setting AUth state
        setStore(state, action) {
            state.store = action.payload;
        },
        setStores(state, action) {
            state.stores = action.payload;
        }
    }
});

export const StoreActions = storeSlice.actions;
export const StoreReducer = storeSlice.reducer;
export default storeSlice;
