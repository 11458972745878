import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './HeaderMenu.css';
import { Avatar, Button, Col, Layout, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import MinoanLogo from '../../assets/icons/svg/Minoan.svg';
import { useUserService } from '../../services';

const { Header } = Layout;
const HeaderMenu = () => {
    const navigate = useNavigate();
    const userService = useUserService();
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingPicture, setLoadingPicture] = useState(false);
    let { imageKey } = useSelector((state) => state.user);
    useEffect(() => {
        async function fetchData() {
            await loadImage();
        }
        fetchData();
    }, [imageKey]);

    const loadImage = async () => {
        setLoadingPicture(true);
        const data = await userService.getProfilePhoto();
        const base64String = btoa(String.fromCharCode(...new Uint8Array(data)));
        setSelectedFile(base64String);
        setLoadingPicture(false);
    }

    const handleProfileClick = (e) => {
        navigate('/profile');
    };
    const handleLogoClick = (e) => {
        navigate('/home');
    };
    return (
        <Header
            style={{
                position: 'fixed',
                zIndex: 100,
                width: '100%',
                background: 'white'
            }}
        >
            <Row>
                <Col span={8}>
                    <div className="logo" onClick={handleLogoClick}>
                        <div className='logo-container'>
                            <img src={MinoanLogo} alt="shopifyIcon" width={110} height={35} style={{ margin: '10px' }}/>
                            {/* <strong style={{ fontSize: 28 }}>StoreSync</strong> */}
                            
                        </div>
                    </div>
                </Col>
                <Col span={16}>
                    <div className="userProfile">
                        <Button size="middle" shape="circle">
                            <Avatar
                                src={selectedFile && <img src={`data:image/png;base64,${selectedFile}`} />}
                                style={{
                                    bottom: '6.5px'
                                }}
                                size="default"
                                icon={!selectedFile && <UserOutlined />} 
                                onClick={handleProfileClick}
                            />
                        </Button>
                    </div>
                </Col>
            </Row>
        </Header>
    );
};

export default HeaderMenu;
