import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Col, Form, Input, Row, Card, Spin } from 'antd';
import ErrorMsg from '../../components/ErrorMsg/ErrorMsg';
import SuccessMsg from '../../components/SuccessMsg/SuccessMsg';
import { useUserService } from '../../services';
import './Register.css';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
};
const Register = () => {
    const [form] = Form.useForm();

    const initialInputUserState = {
        fullName: '',
        password: '',
        email: '',
        confirmPassword: ''
    };

    const userService = useUserService();
    const [inputUser, setInputUser] = useState(initialInputUserState);
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();

    //Helper for loader
    const [loading, setLoading] = useState(false);

    const handleInputChange = (field, value) => {
        setInputUser({
            ...inputUser,
            [field]: value
        });
    };

    const handleSubmit = async () => {
        try {
            let user = {
                // inputs a new user into the mongoDb base
                email: inputUser.email,
                name: inputUser.fullName,
                password: inputUser.password
            };
            const regEx = new RegExp(
                /^(?=.*[0-9])(?=.*[!@#$%&])(?=.*[A-Z])(?=.*[a-z]).{8,30}$/
            );

            if (inputUser.password !== inputUser.confirmPassword) {
                // if the confirmed password is not the same as the initial
                setErrorMsg(`Password and confirm password doesn't match.`);
                return;
            }

            if (!regEx.test(inputUser.password)) {
                setErrorMsg(
                    'Password must contains at least one number, one capital letter, one small letter, one special character.'
                );
                return;
            }
            if (
                inputUser.email.length === 0 ||
                inputUser.fullName.length === 0 ||
                inputUser.password.length === 0 ||
                inputUser.confirmPassword.length === 0
            ) {
                setErrorMsg('Please enter in the missing field(s)');
                return;
            }
            setLoading(true);
            await userService.register(user);
            setSuccessMsg(
                'User successfully registered! Please head to login page to sign in.'
            );
            setErrorMsg(null);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setErrorMsg(
                err.msg ||
                'Unable to create the user at the moment. Please try again later'
            );
            setSuccessMsg(null);
            setLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>
            <div
                className="signUP_form"
                style={{ background: 'rgb(214, 241, 241)' }}
            >
                <Row>
                    <Col span={8}>
                        <div className="leftPanel">
                            <h2>
                                <strong>StoreSync</strong>
                            </h2>
                        </div>
                    </Col>

                    <Col span={16}>
                        <Card className="signUp_Card">
                            <Row>
                                <Col span={3}></Col>
                                <Col span={21}>
                                    <h2>
                                        <strong>Sign Up</strong>
                                    </h2>
                                    <Form
                                        {...formItemLayout}
                                        form={form}
                                        className="signUp-form"
                                        name="register"
                                        layout="vertical"
                                        onFinish={handleSubmit}
                                        scrollToFirstError
                                    >
                                        <Form.Item
                                            name={"fullName"}
                                            label="Full Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please fill your full name!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'fullName',
                                                        e.target.value
                                                    )
                                                }
                                                className="firstName"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            label="E-mail"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message:
                                                        'The input is not valid E-mail!'
                                                },
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your E-mail!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'email',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="password"
                                            label="Password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your password!'
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            !value || value.length > 7
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                'The password you entered is less than 8 characters!'
                                                            )
                                                        );
                                                    }
                                                })
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'password',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="confirm"
                                            label="Confirm Password"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please confirm your password!'
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            !value ||
                                                            getFieldValue(
                                                                'password'
                                                            ) === value
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                'The passwords that you entered do not match!'
                                                            )
                                                        );
                                                    }
                                                })
                                            ]}
                                        >
                                            <Input.Password
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'confirmPassword',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                        <Row className="errorMsg">
                                            {errorMsg && (
                                                <ErrorMsg msg={errorMsg} />
                                            )}
                                        </Row>
                                        <Row className="successMsg">
                                            {successMsg && (
                                                <SuccessMsg msg={successMsg} />
                                            )}
                                        </Row>
                                        <Form.Item
                                            name="agreement"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    validator: (_, value) =>
                                                        value
                                                            ? Promise.resolve()
                                                            : Promise.reject(
                                                                new Error(
                                                                    'Should accept agreement'
                                                                )
                                                            )
                                                }
                                            ]}
                                        >
                                            <Checkbox>
                                                By Signing up, I agree with{' '}
                                                <a href="#">Terms of Use</a> &{' '}
                                                <a href="#">Privacy Policy</a>
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="signUp-form-button"
                                            >
                                                Sign up
                                            </Button>
                                            <div className="signInLink">
                                                Already have an account?{' '}
                                                <Link to={'/login'}>
                                                    {' '}
                                                    Sign In!
                                                </Link>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

export default Register;
