import { React, useState, useEffect, useMemo, useCallback } from 'react';
import './Products.css';
import { Button, Table, Modal, Tag, Row, Col } from 'antd';
import {
    SyncOutlined
} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import Select from "react-select";
import syncErrorIcon from '../../assets/icons/svg/syncErrorIcon.svg';
import { useStoreService } from '../..//services';
import { Notification } from '../../utilities/notification';
import { useSearchParams } from 'react-router-dom';
const SyncErrorIcon = () => <img src={syncErrorIcon} alt="syncError Icon" />;

const column = [
    {
        title: 'PRODUCT',
        dataIndex: 'product'

    },
    {
        title: 'PRODUCT TYPE',
        dataIndex: 'product_type'
    },
    {
        title: 'VENDOR',
        dataIndex: 'vendor'
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        render: (_, { status }) => (
            <>
                {status.map((status) => {
                    if (status === 'Synced' || status === 'active') {
                        return (
                            <Tag
                                style={{
                                    background: '#EEFDF3FF',
                                    color: '#117B34FF',
                                    border: 'none',
                                    borderRadius: '10px'
                                }}
                            >
                                <SyncOutlined /> Synced
                            </Tag>
                        );
                    } else if (status === 'Not Synced') {
                        return (
                            <Tag
                                style={{
                                    background: '#F3F4F6FF',
                                    color: 'black',
                                    border: 'none',
                                    borderRadius: '10px'
                                }}
                            >
                                <SyncErrorIcon /> Not Synced
                            </Tag>
                        );
                    } else {
                        return (
                            <Tag
                                style={{
                                    background: '#FEF8F1FF',
                                    color: '#EF9834FF',
                                    border: 'none',
                                    borderRadius: '10px'
                                }}
                            >
                                <SyncOutlined spin /> Pending
                            </Tag>
                        );
                    }
                })}
            </>
        )
    }
];

function ProductDetails() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tabledata, setTabledata] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const [showButtons, setShowButtons] = useState(false);
    const [searchParams] = useSearchParams();
    const hasSelected = selectedRowKeys.length >= 0;
    const [modalOpen, setModalOpen] = useState(false);
    const [currentStoreSelected, setCurrentStoreSelected] = useState(null);
    const [currentStores, setCurrentStores] = useState([]);
    const [otherStores, setOtherStores] = useState([]);
    const [otherStoreSelected, setOtherStoreSelected] = useState(null);
    const [loadingStores, setLoadingStores] = useState(false);
    const [isSyncing, setIsSyncing] = useState(true);
    const [syncStatus, setSyncStatus] = useState(null);

    const storeService = useStoreService();
    useEffect(() => {
        (async () => {
            setLoadingStores(true);
            const response = await storeService.getAllStores();
            setCurrentStores(response?.filter(a => a.storeSyncId));

            const sourceStoreSyncId = searchParams.get('sourceStoreSyncId');
            const destinationStoreSyncId = searchParams.get('destinationStoreSyncId');
            const page = searchParams.get('page');
            if(sourceStoreSyncId && destinationStoreSyncId && page){
                setCurrentPage(page);
                setCurrentStoreSelected({ storeSyncId: sourceStoreSyncId, type: 'source'});
                setOtherStoreSelected({ storeSyncId: destinationStoreSyncId, type: 'destination'});
                await handleOtherDropdownChange({ storeSyncId: destinationStoreSyncId} , '', page, {
                    currentStoreSelected: { storeSyncId: sourceStoreSyncId, type: 'source'},
                    otherStoreSelected: { storeSyncId: destinationStoreSyncId, type: 'destination'}
                });
            }
            setLoadingStores(false);
        })();
    }, []);

    const insertUrlParam = (key, value) => {
        if (window.history.pushState) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set(key, value);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    const currentDropDownOptions = useMemo(() => {
        return currentStores?.map((store) => ({
            label: store.storeUrl,
            value: store.storeSyncId
        }));
    }, [currentStores]);

    const otherDropDownOptions = useMemo(() => {
        return otherStores?.map((store) => ({
            label: store.storeUrl,
            value: store.storeSyncId
        }));
    }, [otherStores]);

    const handleCurrentStoreChange = useCallback(async (value, searchText = '', page = 1) => {
        try {
            // setLoadingTable(true);
            setTabledata([]);
            const item = currentStores.find(a => a.storeSyncId === value);
            let responseStoreData = [];
            if (item.type === 'source') {
                const response = await storeService.getDestinationStores(
                    {
                        storeSyncId: item?.storeSyncId,
                        searchText: searchText,
                        page: page
                    });
                responseStoreData = response.data.data;
            } else {
                const response = await storeService.getSourceStores(
                    {
                        storeSyncId: item?.storeSyncId,
                        searchText: searchText,
                        page: page
                    });
                responseStoreData = response.data.data;
            }
            // setTotalRecords(responseStoreData?.count);
            let processedData = responseStoreData?.storeData?.map((item) => {
                return {
                    key: item._id,
                    storeUrl: item.storeUrl,
                    storeSyncId: item.storeSyncId,
                    status: [item.status]
                }
            });
            // setLoadingTable(false);
            setOtherStores(processedData);
            setOtherStoreSelected(null);
        } catch (error) {
            console.log(error);
            // setLoadingTable(false);
            setOtherStores([]);
        }
    }, [currentStores, storeService]);

    const handleOtherDropdownChange = useCallback(async (otherStoreData, searchText = '', page = 1, initialState = {}) => {
        setIsSyncing(true);
        setLoadingTable(true);
        let requestbody = {};
        if (currentStoreSelected?.type === 'source' || initialState?.currentStoreSelected?.type === 'source') {
            requestbody = {
                searchText: "",
                page: page,
                sourceStoreSyncId: currentStoreSelected?.storeSyncId || initialState?.currentStoreSelected?.storeSyncId,
                destinationStoreSyncId: otherStoreData?.storeSyncId || initialState?.otherStoreSelected?.storeSyncId
            }
            insertUrlParam('sourceStoreSyncId', currentStoreSelected?.storeSyncId || initialState?.currentStoreSelected?.storeSyncId);
            insertUrlParam('destinationStoreSyncId', otherStoreData?.storeSyncId || initialState?.otherStoreSelected?.storeSyncId);
            insertUrlParam('page', page);   
        } else {
            requestbody = {
                searchText: "",
                page: page,
                sourceStoreSyncId: otherStoreData?.storeSyncId || initialState?.otherStoreSelected?.storeSyncId,
                destinationStoreSyncId: currentStoreSelected?.storeSyncId || initialState?.currentStoreSelected?.storeSyncId
            }
            insertUrlParam('sourceStoreSyncId', otherStoreData?.storeSyncId || initialState?.otherStoreSelected?.storeSyncId);
            insertUrlParam('destinationStoreSyncId', currentStoreSelected?.storeSyncId || initialState?.currentStoreSelected?.storeSyncId);
            insertUrlParam('page', page);            
        }
        try {
            const response = await storeService.getProducts(requestbody);
            const products = response.data.products;
            setTotalRecords(response?.data?.productCount);
            let processedData = products?.map((item) => {
                return {
                    key: item.id,
                    product: item.title,
                    storeUrl: item.storeUrl,
                    storeSyncId: item.storeSyncId,
                    status: [item.status],
                    product_type: item.product_type,
                    vendor: item.vendor
                }
            });
            setLoadingTable(false);
            setTabledata(processedData);
            setCurrentPage(page);
        } catch (error) {
            console.log(error);
            setLoadingTable(false);
            setTabledata([]);
        }
        try {
            const syncResponse = await storeService.getSyncLogs(requestbody);
            if (syncResponse?.data?.data?.length === 0) {
                setIsSyncing(false);
                setSyncStatus(null);
            } else if (syncResponse?.data?.data?.[0]?.status === "completed") {
                setIsSyncing(false);
                setSyncStatus("completed");
            } else {
                //pending
                setSyncStatus(syncResponse?.data?.data?.[0]?.status);
                setIsSyncing(true);
            }
        } catch (error) {
            console.log(error);

        }
    }, [currentStoreSelected, storeService, setSyncStatus]);

    const startBulkMaping = useCallback(async () => {
        try {
            setLoading(true);
            setIsSyncing(true);
            let requestbody = {};
            if (currentStoreSelected?.type === 'source') {
                requestbody = {
                    sourceStoreSyncId: currentStoreSelected?.storeSyncId,
                    destinationStoreSyncId: otherStoreSelected?.storeSyncId
                }
            } else {
                requestbody = {
                    destinationStoreSyncId: currentStoreSelected?.storeSyncId,
                    sourceStoreSyncId: otherStoreSelected?.storeSyncId
                }
            }
            await storeService.syncProducts(requestbody);
            setSyncStatus('pending');
            Notification('success', 'Bulk mapping started successfully. Please come back after sometime.');
            setLoading(false);
            setModalOpen(false);
        } catch (error) {
            console.log(error);
            Notification('error', error.response.data.msg);
            setLoading(false);
            setModalOpen(false);
            setIsSyncing(false);
        }
    }, [currentStoreSelected, otherStoreSelected, storeService]);

    return (
        <>
            <div
                className="storeSelection"
                style={{
                    position: 'absolute',
                    top: '75px',
                    left: '250px',
                    width: '50%',
                    fontFamily:
                        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-seri'
                }}
            >
                <div style={{ marginTop: 24, fontWeight: 'bold', fontSize: 16, marginBottom: 8, width: '100%' }}>Current Store</div>
                <Row>
                    <Col span={12}>
                        <Select
                            onChange={(data) => {
                                const item = currentStores.find(a => a.storeSyncId === data.value);
                                handleCurrentStoreChange(data.value);
                                setCurrentStoreSelected(item);
                            }}
                            options={currentDropDownOptions}
                            isLoading={loadingStores}
                        />
                    </Col>
                    <Col span={12}>
                        {currentStoreSelected && currentStoreSelected?.type &&
                            <Tag
                                size="l"
                                style={{ marginLeft: 16, marginTop: 4, fontSize: '16px', padding: '4px' }}
                                color={currentStoreSelected?.type === 'source' ? "green" : "orange"}>
                                {currentStoreSelected?.type?.toUpperCase()}
                            </Tag>
                        }
                    </Col>
                </Row>

                <div style={{ marginTop: 24, fontWeight: 'bold', fontSize: 16, marginBottom: 8 }}>{currentStoreSelected?.type === 'source' ? 'Destination' : 'Source'} Store</div>
                <Row>
                    <Col span={12}>
                        <Select
                            value={otherStoreSelected ? {
                                label: otherStoreSelected.storeUrl,
                                value: otherStoreSelected.storeSyncId
                            } : null}
                            options={otherDropDownOptions}
                            isLoading={loadingStores}
                            isSearchable={false}
                            onChange={async (data) => {
                                const item = currentStores.find(a => a.storeSyncId === data.value);
                                setOtherStoreSelected(item);
                                await handleOtherDropdownChange(item);

                            }}
                        />
                    </Col>
                    <Col span={12}>
                        {otherStoreSelected && otherStoreSelected?.type &&
                            <Tag
                                size="l"
                                style={{ marginLeft: 16, marginTop: 4, fontSize: '16px', padding: '4px' }}
                                color={otherStoreSelected?.type === 'source' ? "green" : "orange"}>
                                {otherStoreSelected?.type?.toUpperCase()}
                            </Tag>
                        }
                    </Col>
                </Row>
                {currentStoreSelected && otherStoreSelected &&
                    <Row style={{ marginTop: 16 }}>
                        <Col>
                            <a onClick={() => handleOtherDropdownChange(otherStoreSelected, searchText, 1)}>Refresh</a>
                        </Col>
                    </Row>
                }
            </div>
            <div>
                {currentStoreSelected && otherStoreSelected && (
                    <Tooltip title={syncStatus === 'pending' ? "Bulk mapping is in progress. Please come back after sometime." : ''} placement='bottom'>
                        <Button
                            className={`bulkMapperButton ${isSyncing ? 'bulkMapperDisabled' : ''}`}
                            icon={<SyncOutlined />}
                            type="primary"
                            disabled={isSyncing}
                            onClick={() => setModalOpen(true)}
                            loading={syncStatus === 'pending' ? true : false}
                        >
                            Bulk Mapper
                        </Button>
                    </Tooltip>
                )}
                <Modal
                    title="Bulk Mapper"

                    style={{
                        fontSize: '20px'
                    }}
                    width={580}
                    centered
                    okText="Confirm"
                    open={modalOpen}
                    onOk={async () => {
                        await startBulkMaping();
                    }}
                    onCancel={() => setModalOpen(false)}
                    cancelButtonProps={{ disabled: loading }}
                    okButtonProps={{ disabled: loading, loading: loading }}
                >
                    Once you click on confirm, the source product data will sync
                    to destination store
                </Modal>
            </div>
            <div className="productTable">
                {showButtons && (
                    <div
                        style={{
                            marginBottom: 16
                        }}
                    >
                        <span
                            style={{
                                marginLeft: 8,
                                fontFamily:
                                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-seri'
                            }}
                        >
                            {hasSelected
                                ? ` ${selectedRowKeys.length} Product Selected`
                                : ''}
                            <Button
                                style={{
                                    left: '2%',
                                    background: 'white',
                                    color: '#535CE8FF',
                                    border: '1px solid'
                                }}
                                type="primary"
                            >
                                Sync
                            </Button>
                            <Button
                                style={{
                                    left: '4%',
                                    background: 'white',
                                    color: '#535CE8FF',
                                    border: '1px solid'
                                }}
                                type="primary"
                            >
                                Unsync
                            </Button>
                        </span>
                    </div>
                )}
                <Table
                    // rowSelection={rowSelection}
                    columns={column}
                    dataSource={tabledata}
                    loading={loadingTable}
                    page={currentPage}
                    pagination={
                        {
                            current: currentPage,
                            pageSize: 10,
                            total: totalRecords,
                            onChange: async (page) => {
                                setCurrentPage(page);
                                await handleOtherDropdownChange(otherStoreSelected, searchText, page);
                            }                            
                        }
                    }
                />
            </div>
        </>
    );
}

export default ProductDetails;
