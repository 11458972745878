import { getUser } from './helpers/utility';
import ReduxStore from './redux/store';
import { checkAuthorization } from './redux/actionCreators/auth.actions';

export const boot = () => {
    try {
        const userData = getUser();
        if (userData) {
            ReduxStore.dispatch(checkAuthorization(userData.data.token, userData.data.refreshToken));
        }
    } catch (err) {
        console.log('Error - Booting Error - ', err);
        throw err;
    }
};
