import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from 'react-router-dom';
import NavigationBar from './components/NavigationBar/NavigationBar';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';

//Importing components
import Login from './containers/Login/Login';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import Register from './containers/Register/Register';
import Home from './containers/Home/Home';
import StoreMapping from './containers/StoreMapping/StoreMapping';
import ProductDetails from './containers/Products/Products';
import Settings from './containers/Settings/Settings';
import Profile from './containers/Profile/Profile';

//OAuth
import OAuth from './containers/OAuth/OAuth';
import OAuthBegin from './components/OAuthBegin/OAuthBegin';
import OAuthEnd from './components/OAuthEnd/OAuthEnd';

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/users/reset_password" element={<ResetPassword />} />
                
                <Route path="/" element={<Navigate to="home" />} />

                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <NavigationBar />
                        </PrivateRoute>
                    }
                >
                    <Route path="home" element={<Home />} />
                    <Route path="storeMapping" element={<StoreMapping />} />
                    <Route path="products" element={<ProductDetails />} />
                    <Route path="settings" element={<Settings />} />
                </Route>
                <Route path="profile" element={<Profile />} />

                {/* OAuth Related Routes */}
                <Route
                    path="/oauth"
                    element={
                        <PrivateRoute>
                            <OAuth />
                        </PrivateRoute>
                    }
                >
                    <Route path="begin" element={<OAuthBegin />} />
                    <Route path="end" element={<OAuthEnd />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default AppRouter;
