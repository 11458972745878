import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Form, Input } from 'antd';
import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import ErrorMsg from '../../components/ErrorMsg/ErrorMsg';
import SuccessMsg from '../../components/SuccessMsg/SuccessMsg';
import { useUserService } from '../../services';

import './Login.css';

const Login = (props) => {
    const navigate = useNavigate();

    const { token } = useSelector((state) => state.auth);

    const initialInputUserState = {
        email: localStorage.getItem('rememberMe') || '',
        password: ''
    };

    const [inputUser, setInputUser] = useState(initialInputUserState);
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') || false);

    //Helper for loaders
    const [loading, setLoading] = useState(false);
    const userService = useUserService();
    useEffect(() => {
        //redirect to home if already logged in
        if (token) {
            navigate('/');
        }
        if (props.success) {
            console.log(props.success);
            setSuccessMsg(props.success);
        }
    }, [token, props.success, navigate]);

    const handleInputChange = (field, value) => {
        setInputUser({
            ...inputUser,
            [field]: value
        });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let user = {
                email: inputUser.email,
                password: inputUser.password
            };
            const loginRedirectURL = localStorage.getItem('loginRedirectURL');
            await userService.login(user, loginRedirectURL || null);
            setInputUser({
                email: user.email,
                password: user.password
            });
            localStorage.setItem("rememberMe", rememberMe ? inputUser.email: '')
        } catch (err) {
            console.log(err);
            setLoading(false);
            setErrorMsg(
                'Email or password is incorrect, please enter valid credentials.'
            );
        }
    };

    return (
        <Spin spinning={loading}>
            <div className="signIn_Form">
                <Row className="sample">
                    <Col span={8}></Col>

                    <Col span={8}>
                        <h2 className="productTitle">
                            <strong>StoreSync</strong>
                        </h2>
                        <Card className="loginCard">
                            <h2>
                                <strong>Sign In</strong>
                            </h2>
                            <Form
                                name="normal_login"
                                className="login-form"
                                layout="vertical"
                                initialValues={{
                                    remember: true
                                }}
                                onFinish={handleSubmit}
                            >
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message:
                                                'The input is not valid E-mail!'
                                        },
                                        ({ getFieldValue }) => ({
                                            required: true,
                                            validator(_, value) {
                                                if (value !== ''
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'Please input your E-mail!'
                                                    )
                                                );
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        prefix={
                                            <MailOutlined className="site-form-item-icon" />
                                        }
                                        placeholder="Enter your email"
                                        onChange={(e) =>
                                            handleInputChange(
                                                'email',
                                                e.target.value
                                            )
                                        }
                                        defaultValue={inputUser.email}
                                        value={inputUser.email}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your Password!'
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        type="password"
                                        placeholder="Enter your password"
                                        onChange={(e) =>
                                            handleInputChange(
                                                'password',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Row className="errorMsg">
                                    {errorMsg && <ErrorMsg msg={errorMsg} />}
                                </Row>
                                <Row className="successMsg">
                                    {successMsg && (
                                        <SuccessMsg msg={successMsg} />
                                    )}
                                </Row>
                                <Form.Item>
                                    <Form.Item
                                        name="remember"
                                        noStyle
                                    >
                                        <Checkbox 
                                        checked={rememberMe} 
                                        onChange={(e)=> setRememberMe(e.target.checked)}>
                                            Remember me
                                            </Checkbox>
                                    </Form.Item>

                                    <a
                                        className="login-form-forgot"
                                        href="/forgot_password"
                                    >
                                        Forgot password?
                                    </a>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                    >
                                        Sign in
                                    </Button>
                                    <div className="signUpLink">
                                        Dont have an account?{' '}
                                        <Link to={'/register'}> Sign Up!</Link>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            </div>
        </Spin>
    );
};

export default Login;
