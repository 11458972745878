import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    _id: 'null',
    name: null,
    email: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            //Action to update a user
            state._id = action.payload._id;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.imageKey = action.payload.imageKey;
        }
    }
});

export const UserActions = userSlice.actions;
export const UserReducer = userSlice.reducer;
export default userSlice;
