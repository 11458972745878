import { useNavigate } from 'react-router-dom';

import { BASE_API_URL } from '../constants/routes';
import useFetchWrapper from '../helpers/FetchWrapper';

function useOAuthService() {
    const baseUrl = `${BASE_API_URL}/auth`;

    const navigate = useNavigate();

    const fetchWrapper = useFetchWrapper();

    //OAuth Initiater
    async function beginOAuth(data) {
        try {
            const overallRoute = `${baseUrl}/begin?hmac=${data.hmac}&host=${data.host}&shop=${data.shop}&timestamp=${data.timestamp}`;
            const response = await fetchWrapper.get(overallRoute);
            //If user has token, then move to dashboard else redirection url
            if (response.data.authRoute) {
                window.location.href = response.data.authRoute;
            } else {
                navigate('/');
            }
        } catch (err) {
            console.log(err);
        }
    }

    //OAuth complete
    async function endOAuth(data) {
        try {
            // After users's confirmation, sending session to backend to create token
            const overallRoute = `${baseUrl}/callback?code=${data.code}&hmac=${data.hmac}&host=${data.host}&shop=${data.shop}&state=${data.state}&timestamp=${data.timestamp}`;
            const response = await fetchWrapper.get(overallRoute);
            if (response.data.appInstalled) {
                navigate('/');
            }
        } catch (err) {
            console.log(err);
        }
    }

    return {
        beginOAuth,
        endOAuth
    };
}

export { useOAuthService };
