import React from 'react';
import { Alert, Space } from 'antd';

const Unauthorized = () => {
    return (
        <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
            <Alert
                description="We can't display this page because another site has embedded it. Please open this app in new tab."
                type="error"
            />
            <a href="#" target="_blank">Open this app in new tab</a>
        </Space>
    );
};

export default Unauthorized;
