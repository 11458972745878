import { boot } from './boot';
import AppRouter from './AppRouter';
import Unauthorized from './components/Unauthorized/Unauthorized'
import './App.css';
import { AuthActions } from './redux/slices/auth.slice';
import { useDispatch } from 'react-redux'
import axios from 'axios';
import { BASE_API_URL } from './constants/routes';
import { Service } from 'axios-middleware';

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function App() {
    const service = new Service(axios);
    const dispatch = useDispatch();
    const refreshToken = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const refreshToken = user?.data?.refreshToken;
        try {
            const data = await axios({
                method: "get",
                url: `${BASE_API_URL}/users/refresh_token`,
                headers: {
                    'x-access-Token': `${refreshToken}`,
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + refreshToken
                }
            });
            localStorage.setItem('user', JSON.stringify(data));
            dispatch(AuthActions.setAuth({ token: data?.data?.token, refreshToken: data?.data?.refreshToken }));
        } catch (error) {
            console.log(error);
            dispatch(AuthActions.setAuth(null));
        }
    }
    service.register({
        async onResponseError(err = {}) {
            if (err?.response?.status === 401 && !err.config.url.includes('login') && !err.config.url.includes('refresh_token')) {
                refreshToken();
            }
            throw err;
        }
    });
    if (inIframe()) {
        return <Unauthorized />;
    }
    return <AppRouter />;
}

boot();

export default App;
