import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Profile.css';
import Avatar from 'antd/lib/avatar/avatar';
import Card from 'antd/lib/card/Card';
import Meta from 'antd/lib/card/Meta';
import {
    Button,
    Layout,
    Input,
    Select,
    Tabs,
    Divider,
    Form,
    Image,
    Row,
    Col,
    Typography
} from 'antd';
import HeaderMenu from '../../components/Header/HeaderMenu';
import { useUserService } from '../../services';
import { Notification } from '../../utilities/notification';
import { Spin } from 'antd';
import axios from 'axios';
import { BASE_API_URL } from '../../constants/routes';

const Profile = () => {
    const { token } = useSelector((state) => state.auth);
    let { name, email } = useSelector((state) => state.user);
    const [fullName, setFullName] = useState(name);
    const [loading, setLoading] = useState(false);
    const [overallLoading, setOverallLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingPicture, setLoadingPicture] = useState(false);
    
    const userService = useUserService();
    const { Text } = Typography;
    useEffect(() => {
        setFullName(name);
        if(name){
            setOverallLoading(false);
        }
    }, [name]);

    useEffect(() => {
        async function fetchData() {
            await loadImage();
        }
        fetchData();
    }, []);

    const loadImage = async () => {
        setLoadingPicture(true);
        const data = await userService.getProfilePhoto();
        const base64String = btoa(String.fromCharCode(...new Uint8Array(data)));
        setSelectedFile(base64String);
        setLoadingPicture(false);
    }

    const [form] = Form.useForm();
    const { Content } = Layout;

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let user = {
                name: fullName
            };
            await userService.updateUserInfo(user);
            Notification('success', 'Profile updated successfully.');
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            Notification('error', 'Something went wrong.');
        }
    };

    const handleFileSelect = async(event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        try {
            setOverallLoading(true);
            const response = await axios({
                method: "post",
                url: `${BASE_API_URL}/users/upload_image`,
                data: formData,
                headers: {
                    'x-access-Token': `${token}`,
                    "Content-Type": "multipart/form-data",
                    Authorization: 'Bearer ' + token
                }
            });
            let user = {
                name: fullName,
                imageKey: response.data.data.key
            };
            await userService.updateUserInfo(user);
            await loadImage();
            setOverallLoading(false);
            Notification('success', 'Profile photo updated successfully.');
        } catch (error) {
            console.log(error);
            setOverallLoading(false);
        }
      }

    return (
        <Layout className="layout">
            <HeaderMenu />
            <Spin spinning={overallLoading || loadingPicture}>
            <Content
                className="profileContent"
                style={{
                    position: 'relative',
                    zIndex: 1,
                    padding: '0 24px',
                    minHeight: '280px',
                    overflow: 'auto',
                    height: '100vh'
                }}
            >
                {/* <Card
                    style={{
                        position: 'absolute',
                        width: 305,
                        top: 90,
                        left: 110,
                        height: 400,
                        marginTop: 16,
                        borderRadius: '6px',
                        boxShadow: '0px 0px 1px'
                    }}
                >
                    <Row style={{ justifyContent: 'center' }}>
                        <Col>
                            <Avatar
                                size={88}
                                icon={<UserOutlined />}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <h2 style={{ paddingLeft: 8, paddingTop: 10, width: '100%', textAlign: 'center' }}>
                            {name}
                        </h2>

                    </Row>
                    <Divider />
                </Card> */}

                <Card
                    style={{
                        position: 'absolute',
                        width: 305,
                        top: 90,
                        left: 110,
                        height: 400,
                        marginTop: 16,
                        borderRadius: '6px',
                        boxShadow: '0px 0px 1px'
                    }}
                >
                    <Row>
                        <Col>
                            <Avatar
                                size={88}
                                src={<img src={`data:image/png;base64,${selectedFile}`} />}
                            />
                        </Col>
                        <Col style={{ paddingLeft: 16, paddingTop: 16 }}>
                            <div style={{ marginBottom: 12 }}>
                                <Text>Update your photo</Text>
                            </div>
                           
                            <label for="file-upload" className="custom-file-upload">
                                    Choose Image
                            </label>

                            <input style={ { display: 'none' }} id="file-upload" type="file" onChange={handleFileSelect}/>

                                
                        </Col>
                            <Row>
                                <h2 style={{ paddingLeft: 8, paddingTop: 10 }}>
                                    {name}
                                </h2>
                            </Row>
                    </Row>
                    <Divider />
                </Card>
                <div className="settings">
                    <p>Settings</p>
                    <Tabs className="settingsTabs" defaultActiveKey="1">
                        <Tabs.TabPane tab="General" key="1">
                            <Card className="profileCard">
                                <p
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: '600'
                                    }}
                                >
                                    Profile
                                </p>
                                {/* <p>"Some text"</p> */}
                                <Form layout="vertical" form={form}>
                                    <div className="name">
                                        <Form.Item label="Full name">
                                            <Input
                                                style={{
                                                    width: 300,
                                                    borderRadius: 6
                                                }}
                                                placeholder="Your full name"
                                                value={fullName}
                                                onChange={(event) => { setFullName(event.target.value) }}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Email" style={{ marginLeft: 16 }}>
                                            <span>{email}</span>
                                        </Form.Item>
                                        {/* <Form.Item
                                            label="username"
                                            style={{
                                                position: 'absolute',
                                                left: 340
                                            }}
                                        >
                                            <Input
                                                style={{
                                                    width: 300,
                                                    borderRadius: 6
                                                }}
                                                placeholder="Your username"
                                            />
                                        </Form.Item> */}
                                    </div>
                                    {/* <p>location</p>
                                    <br />
                                    <Select
                                        defaultValue="DropDown"
                                        style={{
                                            width: 612
                                        }}
                                    /> */}
                                </Form>
                                <Button type='primary' onClick={handleSubmit} loading={loading} disabled={loading}>Update</Button>
                            </Card>
                            {/* <Card className="accountCard"> */}
                            {/* <p style={{ fontWeight: 700 }}>Email</p>
                                <p>{email}</p>
                                <br /> */}
                            {/* <p style={{ fontWeight: 700 }}>Password</p>
                                <p>************</p> */}
                            {/* </Card> */}
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab="Notifications" key="2"> */}
                            {/* Content of notification tab */}
                        {/* </Tabs.TabPane> */}
                        {/* <Tabs.TabPane tab="Bilings" key="3">
                            Content of Billings page
                        </Tabs.TabPane> */}
                    </Tabs>
                </div>
            </Content>
            </Spin>
        </Layout>
    );
};
export default Profile;
