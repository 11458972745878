import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useOAuthService } from '../../services';

import './OAuthBegin.css';

//Once OAuth is initiated from shopify, user will be redirected to this page
const OAuthBegin = () => {
    const [searchParams] = useSearchParams();

    const { beginOAuth } = useOAuthService();

    useEffect(() => {
        sendQueryParams();
    }, []); //eslint-disable-line

    // Send Request to backend
    const sendQueryParams = async () => {
        const { hmac, host, shop, timestamp } = getQueryParams();
        //Checking query params to process the request
        if (hmac && host && shop) {
            await beginOAuth({ hmac, host, shop, timestamp });
        }
    };

    //Getting Query Params
    const getQueryParams = () => {
        const hmac = searchParams.get('hmac');
        const host = searchParams.get('host');
        const shop = searchParams.get('shop');
        const timestamp = searchParams.get('timestamp');

        return { hmac, host, shop, timestamp };
    };
    return <div></div>;
};

export default OAuthBegin;
