import React, { useState } from 'react';
import './Settings.css';
import { Button, Select, Tabs, Switch, Divider } from 'antd';
import dataNotFoundIcon from '../../assets/icons/svg/dataNotFound.svg';
const storeType = {};

const colorType = {
    Source: ['#007C29FF'],
    Destination: ['#FE763EFF']
};

const switchArr = [
    'Product Title',
    'Product Description',
    'Product Images',
    'Product Type',
    'Product Vendor',
    'Product Varriants',
    'Product Price',
    'Product Cost Per Unit',
    'Product Barcode',
    'Product SKU',
    'Product Weight',
    'Product Tags',
    'Auto add variants by default'
];

const NoDataFoundIcon = () => <img src={dataNotFoundIcon} alt="nodataicon" />;

const Settings = () => {
    const [storetype1, setStoretype1] = useState('');
    const handleStoreChange = (value) => {
        setStoretype1(storeType[value][0]);
    };

    if (Object.keys(storeType).length === 0) {
        return (
            <p
                style={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontSize: '20px'
                }}
            >
                {' '}
                No stores added yet <NoDataFoundIcon />
            </p>
        );
    }

    return (
        <>
            <div
                className="storeSelection"
                style={{
                    position: 'absolute',
                    top: '75px',
                    left: '250px',
                    fontFamily:
                        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-seri'
                }}
            >
                <h3> {storetype1} Store </h3>
                <Select
                    className="options1"
                    defaultValue={Object.keys(storeType)[0]}
                    style={{
                        width: 300
                    }}
                    onChange={handleStoreChange}
                    options={Object.keys(storeType).map((store) => ({
                        label: store,
                        value: store
                    }))}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                />
                <Button
                    className="storeTypeButton1"
                    size="middle"
                    shape="round"
                    style={{
                        background: `${colorType[storetype1][0]}`
                    }}
                >
                    {storetype1}
                </Button>
            </div>

            <Tabs defaultActiveKey="1" className="switchTabsPane">
                <Tabs.TabPane tab="Product Sync setting" key="1">
                    {switchArr.map((item) => {
                        return (
                            <div>
                                <Switch defaultChecked /> {item} <Divider />
                            </div>
                        );
                    })}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Order Sync setting" key="2">
                    Order Sync Settings page yet to be made
                </Tabs.TabPane>
            </Tabs>
        </>
    );
};

export default Settings;
