import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BASE_API_URL } from '../constants/routes';
import useFetchWrapper from '../helpers/FetchWrapper';
import { AuthActions } from '../redux/slices/auth.slice';
import { UserActions } from '../redux/slices/user.slice';

function useUserService() {
    const baseUrl = `${BASE_API_URL}/users`;

    const fetchWrapper = useFetchWrapper();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    async function login(user, redirectionRoute) {
        try {
            const overallRoute = `${baseUrl}/login`;
            const user_1 = await fetchWrapper.post(overallRoute, user);
            //store user details and jwt token in local storage to keep user logged in between page refreshes
            if(redirectionRoute){
                localStorage.setItem('user', JSON.stringify(user_1));
                dispatch(AuthActions.setAuth({token: user_1.data.token, refreshToken: user_1.data.refreshToken}));
                window.location = redirectionRoute;
            }else {
                localStorage.setItem('user', JSON.stringify(user_1));
                dispatch(AuthActions.setAuth({token: user_1.data.token, refreshToken: user_1.data.refreshToken}));
                //Will get user details in home page
                //get return url from location state or default to home page
                const { from } = location.state || { from: { pathname: '/' } };
                navigate(from);
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async function register(user) {
        try {
            const overallRoute = `${baseUrl}/signup`;
            const user_1 = await fetchWrapper.post(overallRoute, user);
            dispatch(AuthActions.setAuth(user_1.data.token));
            // navigate('/login');
        } catch (err) {
            console.log(err);
            throw err?.response?.data;
        }
    }

    async function logout() {
        try {
            // remove user from local storage, set auth state to null and redirect to login page
            localStorage.removeItem('user');
            dispatch(AuthActions.setAuth({ token: null, refreshToken: null }));
            // TBD - User to be removed or not
            navigate('/login');
        } catch (err) {
            console.log(err);
        }
    }

    const getUserInfo = async () => {
        try {
            const overallRoute = `${baseUrl}/info`;
            const userDetails = await fetchWrapper.get(overallRoute);
            dispatch(UserActions.setUser(userDetails.data.data));

            //Redirecting user if he tried any other URL before login
            const loginRedirectURL = localStorage.getItem('loginRedirectURL');
            if (loginRedirectURL) {
                window.location.href = loginRedirectURL;
                localStorage.removeItem('loginRedirectURL');
            }

            return userDetails;
        } catch (err) {
            console.log('Error - User Service - Getting user info - ', err);
        }
    };

    const getProfilePhoto = async () => {
        try {
            const overallRoute = `${baseUrl}/get_image`;
            const response = await fetchWrapper.get(overallRoute);
            return response?.data?.data?.data;
        } catch (err) {
            console.log('Error - User Service - Getting user profile - ', err);
        }
    };

    const updateUserInfo = async (data) => {
        try {
            const overallRoute = `${baseUrl}/edit`;
            await fetchWrapper.put(overallRoute, data);
            const route = `${baseUrl}/info`;
            const userDetails = await fetchWrapper.get(route);
            dispatch(UserActions.setUser(userDetails.data.data));
            return userDetails;
        } catch (err) {
            console.log('Error - User Service - updating user info - ', err);
        }
    };

    const forgotPassword = async (request) => {
        try {
            const overallRoute = `${baseUrl}/forgot_password`;
            return await fetchWrapper.post(overallRoute, request);
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    const resetPassword = async (request) => {
        try {
            const overallRoute = `${baseUrl}/reset_password`;
            return await fetchWrapper.post(overallRoute, request);
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    return {
        login,
        register,
        logout,
        getUserInfo,
        forgotPassword,
        resetPassword,
        updateUserInfo,
        getProfilePhoto
    };
}

export { useUserService };
