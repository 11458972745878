import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './StoreMapping.css';
import { useStoreService } from '../..//services';
import {
    Button,
    Form,
    Col,
    Row,
    Input,
    Table,
    Space,
    Modal,
    Tag
} from 'antd';
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteFilled,
    PlusCircleFilled
} from '@ant-design/icons';
import Select from "react-select";
import { Empty } from 'antd';
import { Notification } from '../../utilities/notification';
import locationIcon from '../../assets/icons/svg/locationIcon.svg';
import shopifyIcon from '../../assets/icons/svg/shopifyIcon.svg';
const { Search } = Input;
const LocationIcon = () => <img src={locationIcon} alt="locationIcon" />;
const ShopifyIcon = () => <img src={shopifyIcon} alt="shopifyIcon" />;

//Store Mapping

const StoreMapping = () => {
    const requestbody = {
        searchText: '',
        page: 1,
        pageSize: 999,
        sort: 'asc'
    };
    const [modalOpen, setModalOpen] = useState(false);
    const [availableStores, setAvailableStores] = useState([]);
    const [tabledata, setTabledata] = useState([]);
    const [storeSelected, setStoreSelected] = useState(null);
    const storeService = useStoreService();
    const [loadingStores, setLoadingStores] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [isConnectingStore, setIsConnectingStore] = useState(false);
    const [storeSyncId, setStoreSyncId] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [deleteModal, setDeleteModal] = useState(null);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            render: (_, record) => (
                <Space size="middle">
                    <ShopifyIcon />
                </Space>
            )
        },
    
        {
            title: 'Store URL',
            dataIndex: 'storeUrl',
            key: 'storeUrl'
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (_, { status }) => (
                <>
                    {status.map((status) => {
                        if (status === true) {
                            return (
                                <CheckCircleTwoTone
                                    key={status}
                                    twoToneColor="#1DD75BFF"
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            );
                        } else {
                            return (
                                <CloseCircleTwoTone
                                    key={status}
                                    twoToneColor="#F22128FF"
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            );
                        }
                    })}
                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'status',
            render: (_, record) => (
                <Space size="middle">
                        <DeleteFilled
                            style={{
                                color: "#32ADFFFF",
                                fontSize: 20
                            }}
                            onClick={async () => {
                                setDeleteModal(record);
                            }}
                        />
                </Space>
            )
        }
    ];

    useEffect(() => {
        (async () => {
            setLoadingStores(true);
            const response = await storeService.getStores(requestbody);
            setAvailableStores(response?.data?.filter(a => a.storeSyncId));
            setLoadingStores(false);
        })();
    }, []);

    const dropDownOptions = useMemo(() => {
        return availableStores?.map((store) => ({
            key: store._id,
            label: store.storeUrl,
            value: store.storeSyncId
        }));
    }, [availableStores]);

    const handleStoreChange = useCallback(async (value, searchText = '', page = 1) => {
        try {
            setLoadingTable(true);
            const item = availableStores.find(a => a.storeSyncId === value);
            let responseStoreData = [];
            if (item.type === 'source') {
                const response = await storeService.getDestinationStores(
                    {
                        storeSyncId: item?.storeSyncId,
                        searchText: searchText,
                        page: page
                    });
                responseStoreData = response.data.data;
            } else {
                const response = await storeService.getSourceStores(
                    {
                        storeSyncId: item?.storeSyncId,
                        searchText: searchText,
                        page: page
                    });
                responseStoreData = response.data.data;
            }
            setTotalRecords(responseStoreData?.count);
            let processedData = responseStoreData?.storeData?.map((item) => {
                return {
                    key: item._id,
                    storeUrl: item.storeUrl,
                    storeSyncId: item.storeSyncId,
                    status: [item.status]
                }
            });
            setLoadingTable(false);
            setTabledata(processedData);
        } catch (error) {
            console.log(error);
            setLoadingTable(false);
            setTabledata([]);
        }
    }, [availableStores]);

    const connectStore = async () => {
        let request = {};
        if (storeSelected.type === 'source') {
            request = {
                sourceStoreSyncId: storeSelected.storeSyncId,
                destinationStoreSyncId: storeSyncId
            }
        } else {
            request = {
                sourceStoreSyncId: storeSyncId,
                destinationStoreSyncId: storeSelected.storeSyncId
            }
        }
        setIsConnectingStore(true);
        try {
            const response = await storeService.addStoreMap(request);
            setIsConnectingStore(false);
            setModalOpen(false);
            setStoreSyncId('');
            if (response?.data?.success) {
                Notification('success', 'Store connected successfully.');
            } else {
                Notification(
                    'error',
                    response?.msg || 'Error while connecting new store. Please try again later.'
                );
            }
        } catch (err) {
            Notification('error', 'Something went wrong while connecting store.');
            console.log(err);
        }
        setCurrentPage(1);
        await handleStoreChange(storeSelected?.storeSyncId, searchText);
    }

    const onSearch = async (value) => {
        await handleStoreChange(storeSelected?.storeSyncId, value, 1);
    }

    const deleteStoreMap = async () => {
        setLoading(true);
        try {
            if(storeSelected?.type === 'source'){

            }
            const response =
                storeSelected?.type === 'source' ?
                    await storeService.deleteDestinationStore({ sourceStoreSyncId: storeSelected?.storeSyncId, destinationStoreSyncId: deleteModal?.storeSyncId }) :
                    await storeService.deleteSourceStore({ sourceStoreSyncId: deleteModal?.storeSyncId, destinationStoreSyncId: storeSelected?.storeSyncId });
            
            if (response.data.success) {
                Notification('success', 'Store mapping deleted successfully.');
            } else {
                Notification(
                    'error',
                    'Error while deleting store mapping. Please try again later.'
                );
            }
        } catch (err) {
            Notification('error', 'Something went wrong while connecting store.');
            console.log(err);
        }
        setCurrentPage(1);
        await handleStoreChange(storeSelected?.storeSyncId, searchText);
        setLoading(false);
        setDeleteModal(null)
    }

    return (
        <>
            <div
                className="storeSelection"
                style={{
                    position: 'absolute',
                    top: '70px',
                    left: '250px',
                    fontFamily:
                        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-seri'
                }}
            >
                <h3 style={{ marginBottom: '-6px' }}>Current Store</h3>
                <Row>
                    <Col span={12}>
                        <Select
                            onChange={(data) => {
                                const item = availableStores.find(a => a.storeSyncId === data.value);
                                handleStoreChange(data.value);
                                setStoreSelected(item);
                            }}
                            options={dropDownOptions}
                            isLoading={loadingStores}
                        />
                    </Col>
                    <Col span={12}>
                        {storeSelected && storeSelected?.type &&
                            <Tag
                                size="l"
                                style={{ marginLeft: 16, marginTop: 4, fontSize: '16px', padding: '4px' }}
                                color={storeSelected?.type === 'source' ? "green" : "orange"}>
                                {storeSelected?.type?.toUpperCase()}
                            </Tag>
                        }
                    </Col>
                </Row>
            </div>
            {storeSelected && storeSelected?.type && <Button
                id="connectButton"
                onClick={() => setModalOpen(true)}
                type="primary"
                icon={<PlusCircleFilled />}
            >
                {`Connect New ${storeSelected?.type === 'source' ? 'Destination' : 'Source'} Store`}
            </Button>
            }
            <Modal
                width={700}
                title={`Connect to new ${storeSelected?.type === 'source' ? 'destination' : 'source'} store`}
                style={{
                    textAlign: 'left'
                }}
                centered
                open={modalOpen}
                okText="Add Store"
                onOk={connectStore}
                onCancel={() => setModalOpen(false)}
                cancelButtonProps={{ disabled: isConnectingStore }}
                okButtonProps={{ disabled: storeSyncId === '' || isConnectingStore, loading: isConnectingStore }}
            >
                <Form layout="vertical" style={{ marginTop: 32 }}>
                    <Row gutter={[48, 8]}>
                        <Col span={16}>
                            <Form.Item label="Enter StoreSync Key">
                                <Input placeholder="Enter StoreSync Key" value={storeSyncId} onChange={(e) => setStoreSyncId(e.target.value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                open={deleteModal}
                title="Confirmation"
                onCancel={() => setDeleteModal(null)}
                footer={[
                    <Button onClick={() => setDeleteModal(null)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={async () => await deleteStoreMap()}>
                        Yes
                    </Button>
                ]}
            >
                <p>Are you sure you want to delete these store mapping ?</p>
            </Modal>

            {
                tabledata.length !== 0 ?
                    <>
                        <Search
                            className="searchStore"
                            allowClear
                            placeholder="Search store name"
                            onSearch={onSearch}
                            onChange={(e)=> setSearchText(e.target.value)}
                        />
                        <Table
                            className="storeMappingTable"
                            columns={columns}
                            dataSource={tabledata}
                            loading={loadingTable}
                            pagination={
                                {
                                    current: currentPage,
                                    pageSize: 10,
                                    total: totalRecords,
                                    onChange: async (page) => {
                                        setCurrentPage(page);
                                        await handleStoreChange(storeSelected?.storeSyncId, searchText, page);
                                    }
                                    
                                }
                            }
                        />
                    </> :
                    <p
                        style={{
                            position: 'absolute',
                            top: '40%',
                            left: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            fontSize: '20px'
                        }}
                    >
                        <div><Empty 
                        image={Empty.PRESENTED_IMAGE_SIMPLE} 
                        description={
                            <span>
                              {storeSelected ? 'No store mapping found' : 'Please select a store'}
                            </span>
                          }
                        /></div>
                    </p>
            }
        </>
    );
};
export default StoreMapping;
