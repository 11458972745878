import React from 'react';
import { Avatar, Button, Col, Layout, Menu, Row } from 'antd';

import './NavigationBar.css';

import { Outlet } from 'react-router-dom';
import SiderMenu from '../Sider/SiderMenu';
import HeaderMenu from '../Header/HeaderMenu';
const { Content } = Layout;

const Navigation = () => {
    return (
        <Layout className="layout">
            <HeaderMenu />

            <SiderMenu />

            <Content
                style={{
                    padding: '0 24px',
                    minHeight: 280
                }}
            >
                <Outlet />
            </Content>
        </Layout>
    );
};

export default Navigation;
