import React, { useState } from 'react';
import { Button, Card, Form, Input } from 'antd';
import { Col, Row, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import ErrorMsg from '../../components/ErrorMsg/ErrorMsg';
import { useSearchParams } from 'react-router-dom';
import { useUserService } from '../../services';
import { Notification } from '../../utilities/notification';
import { useDispatch } from 'react-redux';
import './ResetPassword.css';

const ResetPassword = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const initialInputUserState = {
        password: '',
        confirmPassword: ''
    };

    const [inputUser, setInputUser] = useState(initialInputUserState);
    const [errorMsg, setErrorMsg] = useState();

    //Helper for loaders
    const [loading, setLoading] = useState(false);
    const userService = useUserService();

    const handleInputChange = (field, value) => {
        setInputUser({
            ...inputUser,
            [field]: value
        });
    };

    const handleSubmit = async () => {
        try {
            if (inputUser.password !== inputUser.confirmPassword) {
                setErrorMsg(
                    `Password and confirm password doesn't match.`
                );
                return;
            }
            const regEx = new RegExp(
                /^(?=.*[0-9])(?=.*[!@#$%&])(?=.*[A-Z])(?=.*[a-z]).{8,30}$/
            );

            if (!regEx.test(inputUser.password)) {
                setErrorMsg(
                    'Password must contains at least one number, one capital letter, one small letter, one special character.'
                );
                return;
            }
            setLoading(true);
            let data = {
                key: searchParams.get('key'),
                password: inputUser.password
            };
            await userService.resetPassword(data);
            await userService.logout();
            Notification('success', 'Password changed successfully.');
            setLoading(false);
            navigate('/login');
        } catch (err) {
            console.log(err);
            setLoading(false);
            setErrorMsg(
                'Something went wrong. Please try after sometime.'
            );
        }
    };

    return (
        <Spin spinning={loading}>
            <div className="resetPasswordForm">
                <Row className="sample">
                    <Col span={8}></Col>

                    <Col span={8}>
                        <h2 className="productTitle">
                            <strong>StoreSync</strong>
                        </h2>
                        <Card className="resetPasswordCard">
                            <h2>
                                <strong>Reset password</strong>
                            </h2>
                            <Form
                                name="normal_login"
                                className="reset-password-form"
                                layout="vertical"
                                initialValues={{
                                    remember: true
                                }}
                                onFinish={handleSubmit}
                            >
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your Password!'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value || value.length > 7
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'The password you entered is less than 8 characters!'
                                                    )
                                                );
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        type="password"
                                        placeholder="Enter your password"
                                        onChange={(e) =>
                                            handleInputChange(
                                                'password',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your Confirm Password!'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value || value.length > 7
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'The password you entered is less than 8 characters!'
                                                    )
                                                );
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        type="password"
                                        placeholder="Enter your confirm password"
                                        onChange={(e) =>
                                            handleInputChange(
                                                'confirmPassword',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Row className="errorMsg">
                                    {errorMsg && <ErrorMsg msg={errorMsg} />}
                                </Row>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="resetPasswordButton"
                                    >
                                        Reset Password
                                    </Button>
                                </Form.Item>
                                <a
                                    className="goBack"
                                    href="/login"
                                >
                                    Back
                                </a>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            </div>
        </Spin>
    );
};

export default ResetPassword;
