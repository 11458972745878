import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './Home.css';
import { Input, Table, Space, Modal, Spin, Button, Tag, message } from 'antd';
import { Row, Form } from 'react-bootstrap';
import { STORE_TYPES } from '../../constants/storeTypes';
import { useStoreService } from '../../services';
import {
    ShopOutlined,
    CopyTwoTone,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteFilled
} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import shopifyIcon from '../../assets/icons/svg/shopifyIcon.svg';
import Select from "react-select";
import { Notification } from '../../utilities/notification';
const { Search } = Input;
const ShopifyIcon = () => <img src={shopifyIcon} alt="shopifyIcon" />;

function Home() {

    const { token } = useSelector((state) => state.auth);

    let { stores } = useSelector((state) => state.store);
    console.log(stores)
    let { name } = useSelector((state) => state.user);
    const initialStoreState = {
        store_url: '',
        type: STORE_TYPES[0].value
    };

    const [loading, setLoading] = useState(false);
    const [addStoremodal, setAddStoreModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    //Helper for store the form data
    const [storeForm, setStoreForm] = useState(initialStoreState);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (token) {
            getStores();
        }
    }, [token]); //eslint-disable-line

    const storeService = useStoreService();

    const [storeFormErrors, setStoreFormErrors] = useState([]);

    //https://dev.to/alecgrey/controlled-forms-with-front-and-backend-validations-using-react-bootstrap-5a2
    const handleInputChange = (field, value) => {
        setStoreForm({
            ...storeForm,
            [field]: value
        });
        // Check and see if errors exist, and remove them from the error object:
        if (!!storeFormErrors[field])
            setStoreFormErrors({
                ...storeFormErrors,
                [field]: null
            });
    };

    //Getting all stores
    const getStores = async (request) => {
        try {
            setLoading(true);
            const response = await storeService.getStores(request);
            
            if(response && response?.count){
                setTotalRecords(response?.count);
            }            
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };


    const clearForm = () => {
        setStoreForm(initialStoreState);
    };

    const findStoreFormErrors = () => {
        const { store_url, type } = storeForm;
        const regEx = new RegExp(
            /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/
        );
        const newErrors = {};
        // store_url errors
        if (!type || type === '') newErrors.type = 'Please input your store type';
        // url errors
        if (!store_url || store_url === '')
            newErrors.store_url = 'Please input your store URL';
        else if (!regEx.test(store_url))
            newErrors.store_url = 'Please enter a proper URL';

        return newErrors;
    };

    //when submitting, run this function
    async function handleSubmit(e) {
        try {
            // get our new errors
            const newErrors = findStoreFormErrors();
            // Conditional logic:
            if (Object.keys(newErrors).length > 0) {
                // We got errors!
                setStoreFormErrors(newErrors);
                console.log('err', newErrors);
            } else {
                setAddStoreModal(false);
                let store = {
                    store_url: storeForm.store_url,
                    type: storeForm.type
                };
                const response = await storeService.addStore(store);
                if (response?.data?.success) {
                    Notification('success', 'New store created successfully.');
                    window.open('https://partners.shopify.com/3061573/apps/52530806785/test', '_blank');
                    await getStores();
                } else {
                    Notification(
                        'error',
                        response?.msg ||'Error while creating new store. Please try again later'
                    );
                }
                setStoreForm(initialStoreState);
            }
        } catch (err) {
            Notification('error', 'Something went wrong while creating store.');
            console.log(err);
        }
    }

    const columns = [
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            render: (_, record) => (
                <Space size="middle">
                    <ShopifyIcon />
                </Space>
            )
        },
        {
            title: 'Store Type',
            dataIndex: 'type',
            key: 'type',
            
            render: (_, { type }) => (
                <>
                <Tag color={type === 'source' ? "green" : "orange"}>{type?.toUpperCase()}</Tag>
                </>
            )
        },
        {
            title: 'Store URL',
            dataIndex: 'storeUrl',
            key: 'storeUrl'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <>
                    {status === true ? (
                        <CheckCircleTwoTone
                            twoToneColor="#1DD75BFF"
                            style={{
                                fontSize: 20
                            }} />
                    ) : (
                        <CloseCircleTwoTone
                            twoToneColor="#F22128FF"
                            style={{
                                fontSize: 20
                            }} />
                    )}
                </>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {record?.storeSyncId &&
                        <>
                        <Tooltip title="Uninstall store" placement='bottom'>
                            <DeleteFilled
                                style={{
                                    color: "#32ADFFFF",
                                    fontSize: 20
                                }}
                                onClick={async () => {
                                    setDeleteModal({ storeSyncId: record?.storeSyncId });
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Copy store sync ID" placement='bottom'>
                            <CopyTwoTone
                                style={{
                                    color: "#32ADFFFF",
                                    fontSize: 20
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(record?.storeSyncId);
                                    messageApi.open({
                                        type: 'success',
                                        content: 'Store sync id copied'
                                    });
                                }}
                            />
                        </Tooltip>
                        </>
                    }
                </Space>
            )
        }
    ];
    const onSearch = async (value) => {
        setLoading(true);
        await storeService.getStores({ searchText: value });
        setLoading(false);
    }

    const dropDownOptions = useMemo(() => {
        return STORE_TYPES?.map((store) => ({
            label: store.title,
            value: store.value
        }));
    }, []);

    return (
        <Spin spinning={loading}>
            {contextHolder}
            <h2 className="heading">Welcome {name}</h2>
            <Search
                className="search"
                allowClear
                placeholder="Search store name"
                onSearch={onSearch}
            />
            <Button
                className="addStore"
                onClick={() => setAddStoreModal(true)}
                type="primary"
                icon={<ShopOutlined />}
                style={{
                    position: 'absolute',
                    borderRadius: '6px',
                    padding: '0 12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#005289ff',
                    background: '#99d6ffff',
                    opacity: '1',
                    border: 'none',
                    gap: '6px'
                }}
            >
                Add Store
            </Button>
            <Modal
                width={700}
                title="Add new store"
                style={{
                    textAlign: 'left'
                }}
                centered
                open={addStoremodal}
                okText="Add Store"
                onOk={handleSubmit}
                onCancel={() => setAddStoreModal(false)}
            >
                <Form onReset={clearForm} onSubmit={handleSubmit}>
                    {/* Form Group for Store Name, apply comments throughout other form groups */}
                    <Form.Group className="mb-3">
                        <Row style={{ marginBottom: 16, marginTop: 32 }}>
                            <Form.Label style={{ marginRight: 16 }}>Store URL</Form.Label>
                            <Input
                                placeholder="Enter Store URL"
                                value={storeForm.store_url}
                                style={{ width: "70%", display: "block" }}
                                onChange={(e) =>
                                    handleInputChange('store_url', e.target.value)
                                }
                            />
                            <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                                {storeFormErrors.store_url}
                            </Form.Control.Feedback>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3" style={{ width: "50%", marginBottom: 16 }}>
                        {/* Label in user interface */}
                        <Form.Label style={{ marginRight: 16, display: 'block' }}>Store Type</Form.Label>
                        {/* Attributes of form */}
                        {/* <Form.Select
                            required
                            placeholder="Select Store Type"
                            value={storeForm.type}
                            name="type"
                            id="type"
                            onChange={(e) =>
                                handleInputChange('type', e.target.value)
                            }
                            isInvalid={!!storeFormErrors.type}
                        >
                            {STORE_TYPES.map((storeType) => (
                                <option
                                    value={storeType.value}
                                    key={storeType.value}
                                >
                                    {storeType.title}
                                </option>
                            ))}
                        </Form.Select> */}
                        <Select
                            value={dropDownOptions.find(a => a.value === storeForm.type)}
                            onChange={(data) => {
                                handleInputChange('type', data.value);
                            }}
                            options={dropDownOptions}
                        />
                        {/* alert user of specific form errors */}
                        <Form.Control.Feedback type="invalid">
                            {storeFormErrors.type}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal>
            <Modal
                open={deleteModal}
                title="Confirmation"
                onCancel={() => setDeleteModal(null)}
                footer={[
                    <Button onClick={() => setDeleteModal(null)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={async () => {
                        setLoading(true);
                        await storeService.deleteStore(deleteModal?.storeSyncId);
                        Notification('success', 'Store deleted successfully.');
                        setTimeout(async () => {await storeService.getStores()}, 1000);
                        setLoading(false);
                        setDeleteModal(null)
                    }}>
                        Yes
                    </Button>
                ]}
            >
                <p>Are you sure you want to delete the store?</p>
            </Modal>
            <Table
                rowKey={'_id'}
                className="hometable"
                columns={columns}
                dataSource={stores}
                loading={loading}
                pagination={
                    {
                        pageSize: 10,
                        total: totalRecords,
                        onChange: async (page) => {
                            await getStores({ page: page})
                        }
                    }
                }
            />
        </Spin>
    );
}

export default Home;
