import { configureStore } from '@reduxjs/toolkit';

import { AuthReducer } from './slices/auth.slice';
import { StoreReducer } from './slices/store.slice';
import { UserReducer } from './slices/user.slice';

const store = configureStore({
    reducer: {
        auth: AuthReducer,
        user: UserReducer,
        store: StoreReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export default store;
