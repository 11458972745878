import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';

import './OAuth.css';

//All OAuth Routes are linked here
const OAuth = () => {
    return (
        <div className="o-auth-container">
            <Spin spinning size="large" tip="Please Wait..." />
            <Outlet></Outlet>
        </div>
    );
};

export default OAuth;
