import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    refreshToken: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        //Setting AUth state
        setAuth(state, action) {
            state.token = action?.payload?.token;
            state.refreshToken = action?.payload?.refreshToken;            
        }
    }
});

export const AuthActions = authSlice.actions;
export const AuthReducer = authSlice.reducer;
export default authSlice;
