import {
    UploadOutlined,
    ShopOutlined,
    HomeOutlined,
    UnorderedListOutlined,
    SettingOutlined,
    ContainerOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { useUserService } from '../../services';

import './SiderMenu.css';
const { Sider } = Layout;
const SiderMenu = () => {
    const { logout } = useUserService();

    const onClickLogoutHandler = async () => {
        await logout();
    };

    return (
        <Sider
            width={200}
            className="site-layout-background"
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 60,
                bottom: 0,
                background: 'whitesmoke'
            }}
        >
            <Button
                onClick={onClickLogoutHandler}
                className="logOutButton"
                icon={<UploadOutlined rotate="90" />}
                style={{
                    overflow: 'auto',
                    bottom: '1%',
                    position: 'fixed',
                    background: 'whitesmoke',
                    borderRadius: '3px'
                }}
            >
                Logout
            </Button>

            <Menu
                mode="inline"
                style={{
                    height: '100%',
                    borderRight: 0,
                    background: 'whitesmoke'
                }}
            >
                <Menu.Item key="1" icon={<HomeOutlined />}>
                    Home
                    <Link to="home" />
                </Menu.Item>
                <Menu.Item key="2" icon={<ShopOutlined />}>
                    Store Mappings
                    <Link to="storeMapping" />
                </Menu.Item>
                <Menu.Item key="3" icon={<UnorderedListOutlined />}>
                    Product
                    <Link to="products" />
                </Menu.Item>
                {/* <Menu.Item key="4" icon={<ContainerOutlined />}>
                    Order
                    <Link to="order" />
                </Menu.Item>
                <Menu.Item key="5" icon={<SettingOutlined />}>
                    Settings
                    <Link to="settings" />
                </Menu.Item> */}
            </Menu>
        </Sider>
    );
};

export default SiderMenu;
